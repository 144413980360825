import { Checkbox, ComboBox, DefaultButton, PrimaryButton, Stack, Sticky, StickyPositionType, TextField } from "@fluentui/react"
import _, { orderBy, sortBy } from "lodash"
import { useEffect, useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import store from "../../../../store"
import DynamicForm from "../../../dynamic-form/DynamicForm"
import ModalDialog from "../../common/ModalDialog"
import { getGeneralFields, initAclPermission, validateAclPermission } from "./helper"
import slugify from "slugify"
import { IAccess, IAclPermission } from "./types"
import { createUpdateAclPermission } from "../../../../store/admin/aclPermission/action"
import { toast } from "react-toastify"
import { getAclAccessesApi } from "../../../../store/client/solarportal/aclAccess"
import { getSPEstablishmentsApi } from "../../../../store/client/solarportal/schools"


export const OpenAclPermissionModal = (props?: any) => {
  return new Promise<IAclPermission | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (aclPermission?: IAclPermission) => {
      resolve(aclPermission);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add/Edit Acl Permission"
          onDismiss={() => callback(undefined)}
          containerClassName={"modal-size-sm"}
        >
          <EditAclPermissionDialog
            {...props}
            onSave={(aclPermission: IAclPermission | undefined) => callback(aclPermission)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditAclPermissionDialog = (props: AclPermissionProps) => {
  const dispatch = useDispatch();

  const [aclPermission, setAclPermission] = useState<IAclPermission>(_.cloneDeep(props.aclPermission || initAclPermission as IAclPermission))
  const [errors, setErrors] = useState<any>({});
  const [accesses, setAccesses] = useState<IAccess[]>([]);
  const [schools, setSchools] = useState<any[]>([]);
  const [payload, setPayload] = useState<any>({
    _id: aclPermission._id || null,
    name: aclPermission.name || "",
    schools: aclPermission.type === "school" ? aclPermission.data.map(item => item.slug) : [],
    accesses: !aclPermission.type || !["region", "school"].includes(aclPermission.type) && aclPermission.data?.length ? aclPermission.data.flatMap((item: any) => item.accesses.map((access: { _id: any }) => access._id))
      .filter((value, index, self) => self.indexOf(value) === index) : [],
    regionAccesses: aclPermission.type === "region" && aclPermission.data?.length ? aclPermission.data.flatMap((item: any) => item.accesses.map((access: { _id: any }) => access._id))
      .filter((value, index, self) => self.indexOf(value) === index) : [],
    type: aclPermission.type && ["region", "school"].includes(aclPermission.type) ? aclPermission.type : "none",
    description: aclPermission.description || ""
  });
  const fetchAccesses = async () => {
    try {
      const { data } = await getAclAccessesApi({ sortField: 'name', sortBy: "asc", all: "true" }); // Replace with your actual function to fetch accesses
      setAccesses(data);
    } catch (error) {
      console.log(error)
      toast.error("Failed to fetch accesses");
    }

  };
  const fetchSchools = async () => {
    try {
      const { data } = await getSPEstablishmentsApi({ sortBy: 'name', orderBy: "asc", limit: 10000, skip: 0 }); // Replace with your actual function to fetch accesses
      setSchools(data.map((d: any) => ({ key: d.slug, text: d.name })));
    } catch (error) {
      console.log(error)
      toast.error("Failed to fetch schools");
    }

  };
  useEffect(() => {
    fetchAccesses();
    fetchSchools();
  }, []);

  const generalFields = useMemo(() => getGeneralFields({ payload }), [payload]);

  const handleOnSave = async () => {
    const data = { ...payload }
    if (data.type === "school") {
      delete data.regionAccesses
      delete data.accesses
    } else if (data.type === "region") {
      delete data.schools
      delete data.accesses
    } else {
      delete data.schools
      delete data.regionAccesses
      delete data.type
    }
    data.name = data.name.trim()
    const errors = await validateAclPermission(data);
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    if (data._id === null) {
      delete data._id
    }
    await dispatch(createUpdateAclPermission(data))
    // props.onSave(aclPermission)
    return true
  }


  const onPivotLinkClick = async (props: any) => { };

  const handleOnChangeInput = (key: string, value: any) => {
    let data: any = payload
    switch (key) {
      default:
        setPayload(_.set({ ...data }, key, value))
        break;
    }
  }
  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">

        <div className="ms-Grid-row">
          <TextField
            name="name"
            label="Name"
            value={payload?.name}
            className="ms-Grid-col ms-lg4"
            errorMessage={errors.name}
            onChange={(e, item) => handleOnChangeInput("name", item)}
          />
          <DynamicForm
            fields={generalFields}
            data={aclPermission}
            onChange={handleOnChangeInput}
            errors={errors}
          />
          <Checkbox
            checked={payload.isDeleted || false}
            name="isDeleted"
            label="Is Deleted"
            className="ms-Grid-col ms-lg4 margin-top-lg"
            disabled={true}
          />

        </div>

        <div className="ms-Grid-row">
          {payload.type === "school" && (
            <ComboBox
              label="Select School"
              multiSelect
              selectedKey={payload.schools}
              placeholder="Type to filter school name"
              allowFreeform={true}
              autoComplete={"on"}
              options={schools}
              errorMessage={errors.schools}
              className="ms-Grid-col ms-lg12"
              onChange={(e, item) => {
                const selectedKeys = item!.selected ? [...payload.schools, item!.key] : payload.schools.filter((key: any) => key !== item!.key);
                handleOnChangeInput("schools", selectedKeys);
              }}
            />)}
          {payload.type === "region" && (
            <ComboBox
              label="Select Region Access"
              multiSelect
              selectedKey={payload.regionAccesses}
              placeholder="Type to filter"
              allowFreeform={true}
              autoComplete={"on"}
              options={accesses.filter((f: any) => f.type === "region").map((d) => ({ key: d._id, text: d.name })) as any}
              className="ms-Grid-col ms-lg12"
              errorMessage={errors.regionAccesses}
              onChange={(e, item) => {
                const selectedKeys = item!.selected ? [...payload.regionAccesses, item!.key] : payload.regionAccesses.filter((key: any) => key !== item!.key);
                handleOnChangeInput("regionAccesses", selectedKeys);
              }}
            />

          )}
          {payload.type !== "region" && payload.type !== "school" && (

            <ComboBox
              label="Select Access"
              multiSelect
              selectedKey={payload.accesses}
              placeholder="Type to filter"
              allowFreeform={true}
              autoComplete={"on"}
              options={accesses.filter((f: any) => f.type !== "region").map((d) => ({ key: d._id, text: d.name })) as any}
              className="ms-Grid-col ms-lg12"
              errorMessage={errors.accesses}
              onChange={(e, item) => {
                const selectedKeys = item!.selected ? [...payload.accesses, item!.key] : payload.accesses.filter((key: any) => key !== item!.key);
                handleOnChangeInput("accesses", selectedKeys);
              }}
            />)}
        </div>
        <div className="ms-Grid-row">
          <TextField
            name="description"
            defaultValue={payload?.description}
            label="Description"
            multiline
            rows={3}
            autoAdjustHeight
            errorMessage={errors.description}
            onChange={(e, item) => handleOnChangeInput("description", item)}
          />
        </div>
      </div>

      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleOnSave}
              className="btn-primary"
            />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface AclPermissionProps {
  onCancel?: () => void;
  onSave: (aclPermission: IAclPermission) => void;
  aclPermission?: IAclPermission;
}

export default OpenAclPermissionModal;
