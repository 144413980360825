import { ActionButton, Stack } from "@fluentui/react";
import {
  INavLink,
  IRenderGroupHeaderProps,
  Nav,
} from "@fluentui/react/lib/Nav";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../store";
import { logoutUser, registerAuthEvent } from "../../store/admin/action";
import { IAdmin } from "../../store/admin/reducer";
import ScriptTag from "react-script-tag";
import { useMemo } from "react";
import { developers } from "../../utils/util";
import { projectNameChange } from "./project/helper";
function AppMenu() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const getPathKey = (path: string) => path.split("/").join("");
  // @ts-ignore
  window.getPathKey = getPathKey;
  const admin: IAdmin = useSelector<RootState, IAdmin>(
    (state: RootState) => state.web.admin
  );

  const handleLogout = () => {
    dispatch(logoutUser());
    history.push("/");
  };

  if (!admin.authEvent?.logout) {
    window.addEventListener("storage", (e) => {
      if (e.key === "token" && e.oldValue && !e.newValue) {
        history.push("/");
        dispatch(logoutUser());
      }
    });
    registerAuthEvent({ ...admin.authEvent, logout: true });
  }

  const isDevelopers = developers.includes(
    admin.user?.email.toLocaleLowerCase()!
  );

  const userMenu = admin.user?.permission?.includes("userAdmin")
    ? [
      {
        name: "Users",
        icon: "FabricUserFolder",
        url: "/users",
      },
    ]
    : [];

  const funderMenus = admin.user?.funderAdmin && {
    name: "Funder Managment",
    url: "#",
    links: [
      {
        name: "Bond Holders",
        icon: "ReportDocument",
        url: "/funder/bond-holders",
      },
      {
        name: "Bonds",
        icon: "ReportDocument",
        url: "/funder/bonds",
      },
      {
        name: "Bond Offers",
        icon: "ReportDocument",
        url: "/funder/bond-offers",
      },
      {
        name: "Bond Transactions",
        icon: "ReportDocument",
        url: "/funder/bond-transactions",
      },
      {
        name: "Bond Certificate",
        icon: "ReportDocument",
        url: "/funder/certificates",
      },
      {
        name: "Portfolio",
        icon: "ReportDocument",
        url: "/funder/portfolio",
      },
      {
        name: "Funder Config",
        icon: "ReportDocument",
        url: "/funder/config",
      },
    ],
  };

  const settingsMenu = admin.user?.permission?.includes("settings") && {
    name: "Settings",
    url: "#",
    links: [
      {
        name: "Env Config",
        url: "/settings/env/config",
        icon: "ServerEnviroment",
      },
      {
        name: "Region Config",
        url: "/settings/config",
        icon: "Globe",
      },
      {
        name: "Other Config",
        url: "/settings/other/config",
        icon: "Settings",
      },
      {
        name: "Scripts",
        url: "/settings/scripts",
        icon: "Script",
      },
      {
        name: "SFS Translations",
        icon: "FabricSyncFolder",
        url: "/sfs/translations",
      }

    ],
  };

  const teamMenu = {
    name: "Team",
    icon: "Group",
    url: "/team",
  }

  const activityMenu = {
    name: "Activity Log",
    icon: "ProfileSearch",
    url: "/activity"
  }

  const supporterMenu = {
    name: "Supporters",
    icon: "Money",
    url: "/supporters"
  }

  if (admin.user?.permission?.includes("supporter")) {
    if (settingsMenu) {
      settingsMenu.links.push(supporterMenu)
    }
  }

  if (admin.user?.permission?.includes("activity")) {
    if (settingsMenu) {
      settingsMenu.links.unshift(activityMenu)
    }
  }

  if (admin.user?.permission?.includes("team")) {
    if (settingsMenu) {
      settingsMenu.links.push(teamMenu)
    }
  }


  const logsMenu = admin.user?.permission?.includes("itDev") && {
    name: "Logs",
    url: "#",
    links: [
      {
        name: "Core Services",
        icon: "DietPlanNotebook",
        url: "/core-logs",
      },
      {
        name: "Website",
        icon: "DietPlanNotebook",
        url: "/website-logs",
      },
    ],
  };



  let hasTranslationPermission = admin.user?.permission?.includes('translation')

  let menus: INavLink[] = [
    {
      name: "Content Adder",
      icon: "MediaAdd",
      url: "/content",
    },
    {
      name: "Account",
      icon: "DocumentSet",
      url: "/account",
    },
    {
      name: "Blogs",
      icon: "DocumentSet",
      url: "/blogs",
    },
    {
      name: "Faqs",
      icon: "DocumentSet",
      url: "/faqs",
    },
    {
      name: "Solar Portal",
      url: "#",
      links: [
        {
          name: "ACL Access",
          icon: "ReportDocument",
          url: "acl-accesses",
        },
        {
          name: "ACL Permission",
          icon: "ReportDocument",
          url: "/acl-permissions",
        },
        {
          name: "ACL  Role",
          icon: "ReportDocument",
          url: "/acl-roles",
        },
        {
          name: "Sp Users",
          icon: "ReportDocument",
          url: "/sp-users",
        }
      ],
    },
    {
      name: "User",
      url: "#",
      links: [
        ...userMenu,
        {
          name: "User Functions",
          icon: "ReportDocument",
          url: "/user-functions",
        },
        {
          name: "Skills",
          icon: "ReportDocument",
          url: "/user-skills",
        },
      ],
    },
    {
      name: projectNameChange["Project"],
      url: "#",
      links: [
        {
          name: projectNameChange["Project"],
          icon: "ReportDocument",
          url: "/projects",
        },
        {
          name: "Opportunities",
          icon: "Product",
          url: "/opportunities",
        },
        {
          name: "Products",
          icon: "Product",
          url: "/products",
        },
        {
          name: "Workflows",
          icon: "Product",
          url: "/workflows",
        },
        {
          name: "Tasks",
          icon: "Product",
          url: "/tasks",
        },
        {
          name: "Task Types",
          icon: "Product",
          url: "/tasktypes",
        },
      ],
    },
    {
      name: "Schools",
      url: "#",
      links: [
        {
          name: "Schools",
          icon: "Education",
          url: "/schools",
        },
        {
          name: "Comments",
          icon: "Comment",
          url: "/comments",
        },
        {
          name: "Cost Summary",
          icon: "Money",
          url: "/cost/summary",
        },
        {
          name: "Systems",
          icon: "ServerEnviroment",
          url: "/systems",
        },
        {
          name: "Meters",
          icon: "ServerEnviroment",
          url: "/meters",
        },
        {
          name: "Live Meter Data",
          icon: "ServerEnviroment",
          url: "/livedumps",
        },
        {
          name: "Yields",
          icon: "ServerEnviroment",
          url: "/yields",
        },
        {
          name: "RTYields",
          icon: "ServerEnviroment",
          url: "/rt-yields",
        },
      ],
    },
    {
      name: "Billing",
      url: "#",
      links: [
        {
          name: "Readings",
          url: "/billing/readings",
          icon: "SpeedHigh",
        },
        {
          name: "Invoices",
          url: "/billing/invoices",
          icon: "M365InvoicingLogo",
        },
        {
          name: "Period",
          url: "/billing/periods",
          icon: "M365InvoicingLogo",
        },
        {
          name: "Prices",
          url: "/billing/prices",
          icon: "M365InvoicingLogo",
        },
      ],
    },
    {
      name: "Report",
      url: "#",
      links: [
        {
          name: "School",
          url: "/report/School",
          icon: "ReportDocument",
        },
        {
          name: "Invoice",
          url: "/report/invoice",
          icon: "ReportDocument",
        },
        {
          name: "CBS & GSS Report",
          url: "/report/gss-report",
          icon: "ReportDocument",
        },
        {
          name: "Invoice Account Report",
          url: "/report/invoice-account-report",
          icon: "ReportDocument",
        },
        {
          name: "Fit Report",
          url: "/report/fit-report",
          icon: "ReportDocument",
        },
        {
          name: "Generate Report",
          url: "/generate/report",
          icon: "ReportDocument",
        },
        {
          name: "Dataset",
          icon: "ReportDocument",
          url: "/dataset",
        },
        {
          name: "DataFeed",
          icon: "ReportDocument",
          url: "/report/datafeed",
        },
      ],
    },
  ];

  if (hasTranslationPermission) {
    const translationMenu = {
      name: "Translations",
      icon: "Translate",
      url: "/translations",
    }
    menus.splice(3, 0, translationMenu);
  }

  if (logsMenu) menus.unshift(logsMenu);
  if (funderMenus) menus.push(funderMenus);
  if (settingsMenu) menus.push(settingsMenu);


  // const [menuOptions, setMenuOptions] = useState<INavLink[]>(menus);
  // useMemo(() => setMenuOptions(menus), [menus]);

  const handleClickLink = (
    event?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    event?.preventDefault();
    if (item?.url === "#") {
      const chevron = (event?.target as any)
        ?.closest(".ms-Nav-navItem")
        ?.querySelector(".ms-Nav-chevronButton");
      chevron?.click();
      return;
    }
    if (item?.url) history.push(item.url);
  };

  const menuOptions = useMemo(() => (admin.user ? menus : []), [menus]);

  const selectedKey = useMemo(() => getPathKey(location.pathname), [location]);
  const selectedParent = useMemo(() => {
    return menuOptions.find((item) => {
      return item?.links?.find(
        (subitem) => getPathKey(subitem.url) === selectedKey
      );
    });
  }, [menuOptions, selectedKey]);

  return (
    <>
      <ScriptTag
        type="text/javascript"
        src="https://solar4schools.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/24/bc54840da492f9ca037209037ef0522a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=82f03631"
      />
      <ScriptTag
        type="text/javascript"
        src="https://solar4schools.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/24/bc54840da492f9ca037209037ef0522a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=c408bb26"
      />
      <Stack verticalAlign="space-between" className="menu-nav">
        <div style={{ overflow: "hidden auto" }}>
          <Nav
            styles={{
              linkText: {
                color: "white",
              },
              chevronIcon: {
                color: "white",
              },
              link: {
                WebkitTapHighlightColor: "rgb(0 74 138)",
              },
            }}
            selectedKey={selectedKey}
            ariaLabel="Nav basic example"
            onLinkClick={handleClickLink}
            onRenderGroupHeader={(group?: IRenderGroupHeaderProps) => {
              return (
                <Link to="/home">
                  <img
                    src="/images/sfs-logo.png"
                    alt="Solar For Schools Logo"
                    style={{ width: 50, margin: "15px 75px" }}
                    title="Home"
                  />
                </Link>
              );
            }}
            groups={[
              {
                name: "Solar for Schools",
                links: menuOptions.map((item, index) => {
                  return {
                    ...item,
                    key: getPathKey(item.url),
                    isExpanded: item.name === selectedParent?.name,
                    links: item?.links?.map((link, index) => {
                      return {
                        ...link,
                        key: getPathKey(link.url),
                      };
                    }),
                  };
                }),
              },
            ]}
          />
        </div>

        <ActionButton
          onClick={handleLogout}
          iconProps={{ iconName: "SignOut" }}
          style={{ color: "white" }}
          allowDisabledFocus
        >
          Logout
        </ActionButton>
      </Stack>
    </>
  );
}

const styles = {
  nav: {},
};

export default AppMenu;
