import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { getSPUsersApi, updateSPUserRoleApi } from "../../client/solarportal/spUsers";
import { ISPUser, ISPUserFilter } from "../../../components/admin/solarportal/spUsers/types";

// Action Creators

export const setSPUserFilter = (spUserFilter: ISPUserFilter) => {
  return { type: "SET_SPUSER_FILTER", spUserFilter };
};

export const setSPUserList = (
  spUsers: ISPUser[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_SPUSER_LIST", spUsers, totalCount, reset };
};

export const updateSPUserList = (spUser: ISPUser) => {
  return { type: "UPDATE_SPUSER", spUser };
};


export const getSPUsers =
  (params?: ISPUserFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: ISPUserFilter = getState().web.spUser.spUserFilter;
          reqBody = { ...filters };
        }
        const { users, totalCount } = await getSPUsersApi(reqBody);
        dispatch(setSPUserList(users, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        if (e.response && e.response.status === 400 && e.response?.data?.errors?.length) {
          toast.error(e.response?.data?.errors[0]?.message);
        } else {
          console.log(e);
        }
        dispatch(isLoading(false));
      }
    };

export const createUpdateSPUser =
  (params: any, spUser: ISPUser) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { message: msg } = await updateSPUserRoleApi(params)
        dispatch(updateSPUserList(spUser));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };


