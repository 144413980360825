import { useState } from 'react';
import { ActionButton, CheckboxVisibility, Dropdown, IDropdownOption, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../../store';
import { DetailListStickyHeader } from '../../common/DetailListStickyHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { getAclRoleColumns } from './helper';
import DetailsListWapper from '../../../common/DetailsListWapper';
import { PageSize } from '../../common/common';
import Pagination from '../../common/Pagination';
import OpenAclRoleModal from './AclRoleDialog';
import { IAclRoleState } from '../../../../store/admin/aclRole/reducer';
import { IAclRole } from './types';
import { deleteAclRole, getAclRoles, setAclRoleFilter } from '../../../../store/admin/aclRole/action';

const UserFunctionList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { aclRoles, totalCount, aclRoleFilter }: IAclRoleState = useSelector<RootState, IAclRoleState>((state: RootState) => state.web.aclRole)

  const handleOnDelete = async (aclRole: IAclRole) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete acl role",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${aclRole.name}`,
      },
    });
    if (confirm) dispatch(deleteAclRole(aclRole));
  };

  const handleOnEdit = async (aclRole: IAclRole) => {
    await OpenAclRoleModal({ aclRole })
  };

  const headerRow = getAclRoleColumns({
    actions: [
      {
        icon: 'Edit',
        onClick: handleOnEdit,
      },
      // {
      //   icon: "Trash",
      //   onClick: handleOnDelete
      // }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...aclRoleFilter, sortField: field, sortOrder: order };
    dispatch(setAclRoleFilter(params));
    dispatch(getAclRoles(params))
  }

  const loadNextPage = (p: any) => {
    dispatch(setAclRoleFilter({ ...aclRoleFilter, ...p }))
    dispatch(getAclRoles())
  }
  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...aclRoleFilter, page: 1, pageSize: size }
    dispatch(setAclRoleFilter(filters))
    dispatch(getAclRoles(filters))
  };

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={aclRoleFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>

        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={async () => await OpenAclRoleModal({})}
        ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp; Add Acl Role
        </ActionButton>
      </div>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={aclRoles}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={aclRoles.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={aclRoleFilter?.page!}
            pageSize={aclRoleFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default UserFunctionList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

