import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../index";
import { isLoading } from "../action";
import { toast } from "react-toastify";
import { IUserFunctionFilter } from "../../../components/admin/userFunction/types";
import { IUserFunction } from "@solarforschools/sfs-core/dist/settings/types";
import { createUserFunctionAPi, deleteUserFunctionApi, getuserFunctionsApi, updateUserFunctionApi } from "../../client/userFunction";
import { IAclRoleFilter } from "../../../components/admin/solarportal/aclRoles/types";
import { IAclAccess, IAclAccessFilter } from "../../../components/admin/solarportal/aclAccesses/types";
import { createAclAccessApi, deleteAclAccessApi, getAclAccessesApi, updateAclAccessApi } from "../../client/solarportal/aclAccess";

// Action Creators

export const setAclAccessFilter = (aclAccessFilter: IAclAccessFilter) => {
  return { type: "SET_ACL_ACCESS_FILTER", aclAccessFilter };
};

export const setAclAccessList = (
  aclAccesses: any[],
  totalCount: number,
  reset: boolean = true
) => {
  return { type: "SET_ACL_ACCESS_LIST", aclAccesses, totalCount, reset };
};

export const updateAclAccessList = (aclAccess: any) => {
  return { type: "UPDATE_ACL_ACCESS", aclAccess };
};

export const delAclAccess = (_id: any) => {
  return { type: "DELETE_ACL_ACCESS", _id };
};

export const getAclAccesses =
  (params?: IAclAccessFilter, reset: boolean = true) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        let reqBody = params;
        if (!reqBody) {
          const filters: IAclAccessFilter =
            getState().web.userFunction.userFunctionFilter;
          reqBody = { ...filters };
        }
        const { data: accesses, totalCount } = await getAclAccessesApi(reqBody);
        console.log(accesses, totalCount);
        dispatch(setAclAccessList(accesses, totalCount, reset));
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const createUpdateAclAccess =
  (params: IAclAccess) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        const { data: aclAccess, message: msg } = params._id
          ? await updateAclAccessApi(params)
          : await createAclAccessApi(params);
        dispatch(updateAclAccessList(aclAccess));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };

export const deleteAclAccess =
  (aclAccess: IAclAccess) =>
    async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
      try {
        dispatch(isLoading(true));
        const { message: msg } = await deleteAclAccessApi(aclAccess);
        dispatch(delAclAccess(aclAccess._id));
        toast.success(msg);
        dispatch(isLoading(false));
      } catch (e: any) {
        console.log(e);
        dispatch(isLoading(false));
      }
    };
