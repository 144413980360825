import { ParsedUrlQueryInput } from "querystring";
import { solaportaApiHost } from "../../config";
import { generateQueryString } from "../../../utils/util";
import axios from "axios";
import { headers } from "./helper";
import { ObjectId } from "mongodb";

export const getSPUsersApi = (query: any): Promise<any> => {
    if (query.page && query.pageSize) {
        query.limit = query.pageSize;
        query.skip = (+query.page - 1) * (+query.pageSize);
    }
    if (query.sortField) {
        query.sortBy = `${query.sortField}`
    }
    if (query.sortOrder) {
        query.orderBy = `${query.sortOrder}`
    }
    return axios
        .get(
            `${solaportaApiHost}/v1/user?${generateQueryString(query as ParsedUrlQueryInput)}`, { headers }
        )
        .then((res) => res.data);
};
export const updateSPUserRoleApi = (data: { _id: any, roles: ObjectId[] }): Promise<any> => {
    return axios
        .patch(`${solaportaApiHost}/v1/user/${data._id}`, { roles: data.roles }, { headers })
        .then((res) => res.data);
};

