import { IComboBoxStyles, IDropdownStyles, IIconProps } from "@fluentui/react";
import { getSystems } from "../../../store/client/system";
import { StringMap } from "./types";

export const FundingModels = ["-", "CBS", "Salix", "School", "SfS", "SCF", "Guiness"];

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: "100%" },
};
export const comboBoxStyles: Partial<IComboBoxStyles> = {
  container: { width: "100%" },
};

export const PageSize = [
  { key: "10", text: "10" },
  { key: "20", text: "20" },
  { key: "50", text: "50" },
  { key: "100", text: "100" },
  { key: "1000", text: "1000" },
];
export const Regions = [
  { key: "all", text: "All" },
  { key: "CO", text: "CO" },
  { key: "CZ", text: "CZ" },
  { key: "DE", text: "DE" },
  { key: "ES", text: "ES" },
  { key: "GB", text: "GB" },
  { key: "IE", text: "IE" },
  { key: "IN", text: "IN" },
  { key: "NZ", text: "NZ" },
  { key: "--", text: "Other" },
];

export const AclAccessesOptions = [
  { key: "all", text: "All" },
  { key: "feature", text: "Feature" },
  { key: "region", text: "Region" }
];

export const AclPermissionTypes = [
  { key: "all", text: "All" },
  { key: "school", text: "School" },
  { key: "region", text: "Region" },
  { key: "none", text: "None" }
];

export const countries = [
  { key: "all", text: "All" },
  { text: "Afghanistan", key: "AF" },
  { text: "Åland Islands", key: "AX" },
  { text: "Albania", key: "AL" },
  { text: "Algeria", key: "DZ" },
  { text: "American Samoa", key: "AS" },
  { text: "Andorra", key: "AD" },
  { text: "Angola", key: "AO" },
  { text: "Anguilla", key: "AI" },
  { text: "Antarctica", key: "AQ" },
  { text: "Antigua and Barbuda", key: "AG" },
  { text: "Argentina", key: "AR" },
  { text: "Armenia", key: "AM" },
  { text: "Aruba", key: "AW" },
  { text: "Australia", key: "AU" },
  { text: "Austria", key: "AT" },
  { text: "Azerbaijan", key: "AZ" },
  { text: "Bahamas", key: "BS" },
  { text: "Bahrain", key: "BH" },
  { text: "Bangladesh", key: "BD" },
  { text: "Barbados", key: "BB" },
  { text: "Belarus", key: "BY" },
  { text: "Belgium", key: "BE" },
  { text: "Belize", key: "BZ" },
  { text: "Benin", key: "BJ" },
  { text: "Bermuda", key: "BM" },
  { text: "Bhutan", key: "BT" },
  { text: "Bolivia", key: "BO" },
  { text: "Bosnia and Herzegovina", key: "BA" },
  { text: "Botswana", key: "BW" },
  { text: "Bouvet Island", key: "BV" },
  { text: "Brazil", key: "BR" },
  { text: "British Indian Ocean Territory", key: "IO" },
  { text: "Brunei Darussalam", key: "BN" },
  { text: "Bulgaria", key: "BG" },
  { text: "Burkina Faso", key: "BF" },
  { text: "Burundi", key: "BI" },
  { text: "Cambodia", key: "KH" },
  { text: "Cameroon", key: "CM" },
  { text: "Canada", key: "CA" },
  { text: "Cape Verde", key: "CV" },
  { text: "Cayman Islands", key: "KY" },
  { text: "Central African Republic", key: "CF" },
  { text: "Chad", key: "TD" },
  { text: "Chile", key: "CL" },
  { text: "China", key: "CN" },
  { text: "Christmas Island", key: "CX" },
  { text: "Cocos (Keeling) Islands", key: "CC" },
  { text: "Colombia", key: "CO" },
  { text: "Comoros", key: "KM" },
  { text: "Congo", key: "CG" },
  { text: "Congo, Democratic Republic", key: "CD" },
  { text: "Cook Islands", key: "CK" },
  { text: "Costa Rica", key: "CR" },
  { text: 'Cote D"Ivoire', key: "CI" },
  { text: "Croatia", key: "HR" },
  { text: "Cuba", key: "CU" },
  { text: "Cyprus", key: "CY" },
  { text: "Czech Republic", key: "CZ" },
  { text: "Denmark", key: "DK" },
  { text: "Djibouti", key: "DJ" },
  { text: "Dominica", key: "DM" },
  { text: "Dominican Republic", key: "DO" },
  { text: "Ecuador", key: "EC" },
  { text: "Egypt", key: "EG" },
  { text: "El Salvador", key: "SV" },
  { text: "Equatorial Guinea", key: "GQ" },
  { text: "Eritrea", key: "ER" },
  { text: "Estonia", key: "EE" },
  { text: "Ethiopia", key: "ET" },
  { text: "Falkland Islands (Malvinas)", key: "FK" },
  { text: "Faroe Islands", key: "FO" },
  { text: "Fiji", key: "FJ" },
  { text: "Finland", key: "FI" },
  { text: "France", key: "FR" },
  { text: "French Guiana", key: "GF" },
  { text: "French Polynesia", key: "PF" },
  { text: "French Southern Territories", key: "TF" },
  { text: "Gabon", key: "GA" },
  { text: "Gambia", key: "GM" },
  { text: "Georgia", key: "GE" },
  { text: "Germany", key: "DE" },
  { text: "Ghana", key: "GH" },
  { text: "Gibraltar", key: "GI" },
  { text: "Greece", key: "GR" },
  { text: "Greenland", key: "GL" },
  { text: "Grenada", key: "GD" },
  { text: "Guadeloupe", key: "GP" },
  { text: "Guam", key: "GU" },
  { text: "Guatemala", key: "GT" },
  { text: "Guernsey", key: "GG" },
  { text: "Guinea", key: "GN" },
  { text: "Guinea-Bissau", key: "GW" },
  { text: "Guyana", key: "GY" },
  { text: "Haiti", key: "HT" },
  { text: "Heard Island and Mcdonald Islands", key: "HM" },
  { text: "Holy See (Vatican City State)", key: "VA" },
  { text: "Honduras", key: "HN" },
  { text: "Hong Kong", key: "HK" },
  { text: "Hungary", key: "HU" },
  { text: "Iceland", key: "IS" },
  { text: "India", key: "IN" },
  { text: "Indonesia", key: "ID" },
  { text: "Iran", key: "IR" },
  { text: "Iraq", key: "IQ" },
  { text: "Ireland", key: "IE" },
  { text: "Isle of Man", key: "IM" },
  { text: "Israel", key: "IL" },
  { text: "Italy", key: "IT" },
  { text: "Jamaica", key: "JM" },
  { text: "Japan", key: "JP" },
  { text: "Jersey", key: "JE" },
  { text: "Jordan", key: "JO" },
  { text: "Kazakhstan", key: "KZ" },
  { text: "Kenya", key: "KE" },
  { text: "Kiribati", key: "KI" },
  { text: "Korea (North)", key: "KP" },
  { text: "Korea (South)", key: "KR" },
  { text: "Kosovo", key: "XK" },
  { text: "Kuwait", key: "KW" },
  { text: "Kyrgyzstan", key: "KG" },
  { text: "Laos", key: "LA" },
  { text: "Latvia", key: "LV" },
  { text: "Lebanon", key: "LB" },
  { text: "Lesotho", key: "LS" },
  { text: "Liberia", key: "LR" },
  { text: "Libyan Arab Jamahiriya", key: "LY" },
  { text: "Liechtenstein", key: "LI" },
  { text: "Lithuania", key: "LT" },
  { text: "Luxembourg", key: "LU" },
  { text: "Macao", key: "MO" },
  { text: "Macedonia", key: "MK" },
  { text: "Madagascar", key: "MG" },
  { text: "Malawi", key: "MW" },
  { text: "Malaysia", key: "MY" },
  { text: "Maldives", key: "MV" },
  { text: "Mali", key: "ML" },
  { text: "Malta", key: "MT" },
  { text: "Marshall Islands", key: "MH" },
  { text: "Martinique", key: "MQ" },
  { text: "Mauritania", key: "MR" },
  { text: "Mauritius", key: "MU" },
  { text: "Mayotte", key: "YT" },
  { text: "Mexico", key: "MX" },
  { text: "Micronesia", key: "FM" },
  { text: "Moldova", key: "MD" },
  { text: "Monaco", key: "MC" },
  { text: "Mongolia", key: "MN" },
  { text: "Montserrat", key: "MS" },
  { text: "Morocco", key: "MA" },
  { text: "Mozambique", key: "MZ" },
  { text: "Myanmar", key: "MM" },
  { text: "Namibia", key: "NA" },
  { text: "Nauru", key: "NR" },
  { text: "Nepal", key: "NP" },
  { text: "Netherlands", key: "NL" },
  { text: "Netherlands Antilles", key: "AN" },
  { text: "New Caledonia", key: "NC" },
  { text: "New Zealand", key: "NZ" },
  { text: "Nicaragua", key: "NI" },
  { text: "Niger", key: "NE" },
  { text: "Nigeria", key: "NG" },
  { text: "Niue", key: "NU" },
  { text: "Norfolk Island", key: "NF" },
  { text: "Northern Mariana Islands", key: "MP" },
  { text: "Norway", key: "NO" },
  { text: "Oman", key: "OM" },
  { text: "Pakistan", key: "PK" },
  { text: "Palau", key: "PW" },
  { text: "Palestinian Territory, Occupied", key: "PS" },
  { text: "Panama", key: "PA" },
  { text: "Papua New Guinea", key: "PG" },
  { text: "Paraguay", key: "PY" },
  { text: "Peru", key: "PE" },
  { text: "Philippines", key: "PH" },
  { text: "Pitcairn", key: "PN" },
  { text: "Poland", key: "PL" },
  { text: "Portugal", key: "PT" },
  { text: "Puerto Rico", key: "PR" },
  { text: "Qatar", key: "QA" },
  { text: "Reunion", key: "RE" },
  { text: "Romania", key: "RO" },
  { text: "Russian Federation", key: "RU" },
  { text: "Rwanda", key: "RW" },
  { text: "Saint Helena", key: "SH" },
  { text: "Saint Kitts and Nevis", key: "KN" },
  { text: "Saint Lucia", key: "LC" },
  { text: "Saint Pierre and Miquelon", key: "PM" },
  { text: "Saint Vincent and the Grenadines", key: "VC" },
  { text: "Samoa", key: "WS" },
  { text: "San Marino", key: "SM" },
  { text: "Sao Tome and Principe", key: "ST" },
  { text: "Saudi Arabia", key: "SA" },
  { text: "Senegal", key: "SN" },
  { text: "Serbia", key: "RS" },
  { text: "Montenegro", key: "ME" },
  { text: "Seychelles", key: "SC" },
  { text: "Sierra Leone", key: "SL" },
  { text: "Singapore", key: "SG" },
  { text: "Slovakia", key: "SK" },
  { text: "Slovenia", key: "SI" },
  { text: "Solomon Islands", key: "SB" },
  { text: "Somalia", key: "SO" },
  { text: "South Africa", key: "ZA" },
  { text: "South Georgia and the South Sandwich Islands", key: "GS" },
  { text: "Spain", key: "ES" },
  { text: "Sri Lanka", key: "LK" },
  { text: "Sudan", key: "SD" },
  { text: "Suriname", key: "SR" },
  { text: "Svalbard and Jan Mayen", key: "SJ" },
  { text: "Swaziland", key: "SZ" },
  { text: "Sweden", key: "SE" },
  { text: "Switzerland", key: "CH" },
  { text: "Syrian Arab Republic", key: "SY" },
  { text: "Taiwan, Province of China", key: "TW" },
  { text: "Tajikistan", key: "TJ" },
  { text: "Tanzania", key: "TZ" },
  { text: "Thailand", key: "TH" },
  { text: "Timor-Leste", key: "TL" },
  { text: "Togo", key: "TG" },
  { text: "Tokelau", key: "TK" },
  { text: "Tonga", key: "TO" },
  { text: "Trinidad and Tobago", key: "TT" },
  { text: "Tunisia", key: "TN" },
  { text: "Turkey", key: "TR" },
  { text: "Turkmenistan", key: "TM" },
  { text: "Turks and Caicos Islands", key: "TC" },
  { text: "Tuvalu", key: "TV" },
  { text: "Uganda", key: "UG" },
  { text: "Ukraine", key: "UA" },
  { text: "United Arab Emirates", key: "AE" },
  { text: "United Kingdom", key: "GB" },
  { text: "United States", key: "US" },
  { text: "United States Minor Outlying Islands", key: "UM" },
  { text: "Uruguay", key: "UY" },
  { text: "Uzbekistan", key: "UZ" },
  { text: "Vanuatu", key: "VU" },
  { text: "Venezuela", key: "VE" },
  { text: "Viet Nam", key: "VN" },
  { text: "Virgin Islands, British", key: "VG" },
  { text: "Virgin Islands, U.S.", key: "VI" },
  { text: "Wallis and Futuna", key: "WF" },
  { text: "Western Sahara", key: "EH" },
  { text: "Yemen", key: "YE" },
  { text: "Zambia", key: "ZM" },
  { text: "Zimbabwe", key: "ZW" },
];

export const googleMapsApiKey =
  process.env.GOOGLE_MAP_KEY || "AIzaSyB8feShBw3Ql13hdfNp7-9REtrgpHWn69A";

export const requiredMessage: string = "Cannot be empty";

export const currencies = [
  { key: "all", text: "All" },
  { key: "AED", text: "AED" },
  { key: "AFN", text: "AFN" },
  { key: "ALL", text: "ALL" },
  { key: "AMD", text: "AMD" },
  { key: "ANG", text: "ANG" },
  { key: "AOA", text: "AOA" },
  { key: "ARS", text: "ARS" },
  { key: "AUD", text: "AUD" },
  { key: "AWG", text: "AWG" },
  { key: "AZN", text: "AZN" },
  { key: "BAM", text: "BAM" },
  { key: "BBD", text: "BBD" },
  { key: "BDT", text: "BDT" },
  { key: "BGN ", text: "BGN" },
  { key: "BHD", text: "BHD" },
  { key: "BIF", text: "BIF" },
  { key: "BMD", text: "BMD" },
  { key: "BND", text: "BND" },
  { key: "BOB", text: "BOB" },
  { key: "BRL", text: "BRL" },
  { key: "BSD", text: "BSD" },
  { key: "BTN", text: "BTN" },
  { key: "BWP", text: "BWP" },
  { key: "BYR", text: "BYR" },
  { key: "BZD", text: "BZD" },
  { key: "CAD", text: "CAD" },
  { key: "CDF", text: "CDF" },
  { key: "CHF", "text": "CHF" },
  { key: "CLP", text: "CLP" },
  { key: "CNY", text: "CNY" },
  { key: "COP", text: "COP" },
  { key: "CRC", text: "CRC" },
  { key: "CUP", text: "CUP" },
  { key: "CVE", text: "CVE" },
  { key: "CZK", text: "CZK" },
  { key: "DJF", text: "DJF" },
  { key: "DKK", text: "DKK" },
  { key: "DOP", text: "DOP" },
  { key: "DZD", text: "DZD" },
  { key: "EGP", text: "EGP" },
  { key: "ERN", text: "ERN" },
  { key: "ETB", text: "ETB" },
  { key: "EUR", text: "EUR" },
  { key: "FJD", text: "FJD" },
  { key: "FKP", text: "FKP" },
  { key: "GBP", text: "GBP" },
  { key: "GEL", text: "GEL" },
  { key: "GHS", text: "GHS" },
  { key: "GIP", text: "GIP" },
  { key: "GMD", text: "GMD" },
  { key: "GNF", text: "GNF" },
  { key: "GTQ", text: "GTQ" },
  { key: "GYD", text: "GYD" },
  { key: "HKD", text: "HKD" },
  { key: "HNL", text: "HNL" },
  { key: "HRK", text: "HRK" },
  { key: "HTG", text: "HTG" },
  { key: "HUF", text: "HUF" },
  { key: "IDR", text: "IDR" },
  { key: "ILS", text: "ILS" },
  { key: "INR", text: "INR" },
  { key: "IQD", text: "IQD" },
  { key: "IRR", text: "IRR" },
  { key: "ISK", text: "ISK" },
  { key: "JMD", text: "JMD" },
  { key: "JOD", text: "JOD" },
  { key: "JPY", text: "JPY" },
  { key: "KES", text: "KES" },
  { key: "KGS", text: "KGS" },
  { key: "KHR", text: "KHR" },
  { key: "KMF", text: "KMF" },
  { key: "KPW", text: "KPW" },
  { key: "KRW", text: "KRW" },
  { key: "KWD", text: "KWD" },
  { key: "KYD", text: "KYD" },
  { key: "KZT", text: "KZT" },
  { key: "LAK", text: "LAK" },
  { key: "LBP", text: "LBP" },
  { key: "LKR", text: "LKR" },
  { key: "LRD", text: "LRD" },
  { key: "LSL", text: "LSL" },
  { key: "LYD", text: "LYD" },
  { key: "MAD", text: "MAD" },
  { key: "MDL", text: "MDL" },
  { key: "MGA", text: "MGA" },
  { key: "MKD", text: "MKD" },
  { key: "MMK", text: "MMK" },
  { key: "MNT", text: "MNT" },
  { key: "MOP", text: "MOP" },
  { key: "MRO", text: "MRO" },
  { key: "MUR", text: "MUR" },
  { key: "MVR", text: "MVR" },
  { key: "MWK", text: "MWK" },
  { key: "MXN", text: "MXN" },
  { key: "MYR", text: "MYR" },
  { key: "MZN", text: "MZN" },
  { key: "NAD", text: "NAD" },
  { key: "NGN", text: "NGN" },
  { key: "NIO", text: "NIO" },
  { key: "NOK", text: "NOK" },
  { key: "NPR", text: "NPR" },
  { key: "NZD", text: "NZD" },
  { key: "OMR", text: "OMR" },
  { key: "PAB", text: "PAB" },
  { key: "PEN", text: "PEN" },
  { key: "PGK", text: "PGK" },
  { key: "PHP", text: "PHP" },
  { key: "PKR", text: "PKR" },
  { key: "PLN", text: "PLN" },
  { key: "PYG", text: "PYG" },
  { key: "QAR", text: "QAR" },
  { key: "RON", text: "RON" },
  { key: "RSD", text: "RSD" },
  { key: "RUB", text: "RUB" },
  { key: "RWF", text: "RWF" },
  { key: "SAR", text: "SAR" },
  { key: "SBD", text: "SBD" },
  { key: "SCR", text: "SCR" },
  { key: "SDG", text: "SDG" },
  { key: "SEK", text: "SEK" },
  { key: "SGD", text: "SGD" },
  { key: "SHP", text: "SHP" },
  { key: "SLL", text: "SLL" },
  { key: "SOS", text: "SOS" },
  { key: "SRD", text: "SRD" },
  { key: "SSP", text: "SSP" },
  { key: "STD", text: "STD" },
  { key: "SYP", text: "SYP" },
  { key: "SZL", text: "SZL" },
  { key: "THB", text: "THB" },
  { key: "TJS", text: "TJS" },
  { key: "TMT", text: "TMT" },
  { key: "TND", text: "TND" },
  { key: "TOP", text: "TOP" },
  { key: "TRY", text: "TRY" },
  { key: "TTD", text: "TTD" },
  { key: "TWD", text: "TWD" },
  { key: "TZS", text: "TZS" },
  { key: "UAH", text: "UAH" },
  { key: "UGX", text: "UGX" },
  { key: "USD", text: "USD" },
  { key: "UYU", text: "UYU" },
  { key: "UZS", text: "UZS" },
  { key: "VEF", text: "VEF" },
  { key: "VND", text: "VND" },
  { key: "VUV", text: "VUV" },
  { key: "WST", text: "WST" },
  { key: "XAF", text: "XAF" },
  { key: "XCD", text: "XCD" },
  { key: "XOF", text: "XOF" },
  { key: "XPF", text: "XPF" },
  { key: "YER", text: "YER" },
  { key: "ZAR", text: "ZAR" },
  { key: "ZMW", text: "ZMW" },
  { key: "ZWL", text: "ZWL" },
];

export const addIcon: IIconProps = { iconName: "Add" };

export const FundingOptions = [
  { key: "all", text: "All" },
  { key: "CBS", text: "CBS" },
  { key: "KG", text: "KG" },
  { key: "EN", text: "EN" },
  { key: "Durham", text: "Durham" },
  { key: "School", text: "School" },
  { key: "GSS1", text: "GSS1" },
  { key: "GSS2", text: "GSS2" },
  { key: "Project1", text: "Project 1" },
];

export const FundingModelOptions = FundingModels.map((x) => ({
  text: x,
  key: x,
}));

export const ContributionUnitOptions = [
  "-",
  "+/- % Amount",
  "+/- Amount",
  "+/- Amount per kWp",
  "fix Amount per kWp",
  "fix Amount",
].map((x) => ({ text: x, key: x }));

export const GranpaUnitOptions = ["Fix Amount", "% of Totalcosts"].map((x) => ({ text: x, key: x }));

export const PartnerFeeUnitOptions = [
  "-",
  "fix",
  "per kWp",
].map((x) => ({ text: x, key: x }));

export const WorkflowTypes = [
  { key: "all", text: "All" },
  { key: "School", text: "School" },
  { key: "Project", text: "Project" },
];

export const OpportunityStatus = [
  { key: "all", text: "All" },
  { key: "Active", text: "Active" },
  { key: "Lost", text: "Lost" },
  { key: "Disengaged", text: "Disengaged" },
];

export const Sites = [
  { key: "all", text: "All" },
  { key: "student", text: "Students" },
  { key: "schools", text: "Schools" },
  { key: "installers", text: "Installers" },
  { key: "partners", text: "Partners" },
  { key: "funders", text: "Funders" },
  { key: "homepage", text: "HomePage" },
  { key: "blogs", text: "Blogs" },
  { key: "faqs", text: "FAQs" },
  { key: "Mobile", text: "Mobile" },
  { key: "international-community", text: "International Community" },
];

export const Languages = [
  { key: "all", text: "All" },
  { key: "en", text: "English" },
  { key: "en", text: "English (Germany)" },
  { key: "en", text: "English (Spain)" },
  { key: "nz", text: "English (New Zealand)" },
  { key: "nz", text: "English (Ireland)" },
  { key: "de", text: "German" },
  { key: "es", text: "Spanish" },
  { key: "cs", text: "Czech" },
  { key: "in", text: "Englisch (India)" },
];

export const BlogCategories = [
  { key: "all", text: "All" },
  { key: "solarForSchools", text: "Solar For Schools" },
  { key: "students", text: "Students" },
  { key: "schools", text: "Schools" },
  { key: "installers", text: "Installers" },
  { key: "partners", text: "Partners" },
  { key: "funders", text: "Funders" },
  { key: "MobileHint", text: "Mobile Hint" },
  { key: "MobileFurtherInfo", text: "Mobile Further Info" },
  { key: "international-community", text: "International Community" },
];

export const BlogRegion = [
  { key: "all", text: "All" },
  { key: "gb", text: "UK" },
  { key: "de", text: "Germany" },
  { key: "in", text: "India" },
  { key: "es", text: "Spain" },
  { key: "nz", text: "New Zealand" },
  { key: "cz", text: "Czech Republic" },
  { key: "ie", text: "Ireland" },
  { key: "--", text: "Other" },
  { key: "--", text: "Other countries" },
];

export const RecordPerPage = 100

export const ReadingTypes = [
  { key: 'all', text: 'All' },
  { key: '1', text: ' Manual reading provided by school' },
  { key: "2", text: ' Reading provided by meter operator' },
  { key: "3", text: 'Calculated key' },
  { key: "4", text: 'Manual reading provided by SFS' },
  { key: '5', text: 'Automatic reading provided by SFS (default)' }
]

export const getSystemOptions = async (filter: StringMap) => {
  try {
    const { systems } = await getSystems(filter)
    return systems.map((s: { id: any; name: any; }) => {
      return {
        key: s.id.toString(),
        text: `${s.name} (${s.id})`,
        name: s.name
      }
    })
  } catch (error) {
    return []
  }

}

export const months = [
  { key: "Jan", text: "Jan" },
  { key: "Feb", text: "Feb" },
  { key: "Mar", text: "Mar" },
  { key: "Apr", text: "Apr" },
  { key: "May", text: "May" },
  { key: "Jun", text: "Jun" },
  { key: "Jul", text: "Jul" },
  { key: "Aug", text: "Aug" },
  { key: "Sep", text: "Sep" },
  { key: "Oct", text: "Oct" },
  { key: "Nov", text: "Nov" },
  { key: "Dec", text: "Dec" }
]

export const quarters = [{ key: "1", text: "Q1" },
{ key: "2", text: "Q2" },
{ key: "3", text: "Q3" },
{ key: "4", text: "Q4" }]