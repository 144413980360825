import { FontIcon, IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { StringMap } from "../../common/types";
import { IField } from "../../../dynamic-form/DynamicForm";
import { Regions, requiredMessage } from "../../common/common";
import { userRefferences } from "./common";
import { isSortable } from "../../common/utils";
import { ISPUser } from "./types";
import { read } from "fs";

export const validateSPUser = async (params: any) => {
    let errors: StringMap = {};
    if (!params.roles || params.roles.length === 0) {
        errors["roles"] = requiredMessage;
    }
    return errors;
}

export const getSPUserColumns = (params: {
    actions: {
        icon: string,
        onClick: (data: any) => any
    }[]
}): IColumn[] => {
    return [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 200,
            ...isSortable,
            onRender: (item) => <TooltipHost content={item.name} closeDelay={500}> {item.name}</TooltipHost>
        },
        {
            key: 'email',
            name: 'email',
            fieldName: 'email',
            minWidth: 150,
            maxWidth: 250,
            ...isSortable,
            onRender: (item: ISPUser) => <TooltipHost content={item.email} closeDelay={500}> {item.email}</TooltipHost>
        },
        {
            key: 'User Role',
            name: 'Roles',
            fieldName: 'roles',
            minWidth: 120,
            onRender: (item: ISPUser) => <TooltipHost closeDelay={500}> {item.roles?.map((r: { name: string; }) => r.name).join(", ")}</TooltipHost>
        },
        {
            key: 'action',
            name: 'Action',
            fieldName: '-',
            ariaLabel: "Action",
            minWidth: 150,
            maxWidth: 300,
            onRender: (data) => {
                const { permission, active } = data
                return (
                    <div className="action-icons-container">
                        {params.actions.map((action: { icon: string | undefined; onClick: (arg0: any) => void; }) => {
                            if (action.icon === "FileSymLink") {
                                if (active && permission.includes("teacher"))
                                    return <FontIcon
                                        iconName={action.icon}
                                        onClick={() => action.onClick(data)} />;
                                else return <></>
                            }

                            return <FontIcon
                                iconName={action.icon}
                                onClick={() => action.onClick(data)} />;
                        })}
                    </div>
                );
            },
        }
    ];
}

export interface UserTabErrors {
    general: boolean
    schools: boolean
}



