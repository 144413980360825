import axios from "axios";
import { ParsedUrlQueryInput } from "querystring";
import { generateQueryString } from "../../../utils/util";
import { solaportaApiHost } from "../../config";
import { IAclRole } from "../../../components/admin/solarportal/aclRoles/types";
import { headers } from "./helper";

export const getAclRolesApi = (query: any): Promise<any> => {
	if (query.page && query.pageSize) {
		query.limit = query.pageSize;
		query.skip = (+query.page - 1) * (+query.pageSize);
	}
	if (query.sortField) {
		query.sortBy = `${query.sortField}`
	}
	if (query.sortOrder) {
		query.orderBy = `${query.sortOrder}`
	}
	return axios
		.get(
			`${solaportaApiHost}/v1/roles?${generateQueryString(query as ParsedUrlQueryInput)}`, { headers }
		)
		.then((res) => res.data);
};


export const createAclRoleApi = (aclRole: IAclRole): Promise<any> => {
	return axios
		.post(`${solaportaApiHost}/v1/role`, aclRole, { headers })
		.then((res) => res.data);
};

export const updateAclRoleApi = (aclRole: IAclRole): Promise<any> => {
	// if (aclRole.created) delete aclRole.created;
	// if (aclRole.updated) delete aclRole.updated;
	return axios
		.put(`${solaportaApiHost}/v1/role/${aclRole._id}`, aclRole, { headers })
		.then((res) => res.data);
};

export const deleteAclRoleApi = (aclRole: IAclRole): Promise<any> => {
	return axios
		.delete(`${solaportaApiHost}/v1/role/${aclRole._id}`, { headers })
		.then((res) => res.data);
};
