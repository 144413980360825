import { useState } from 'react';
import { ActionButton, CheckboxVisibility, Dropdown, IDropdownOption, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { SelectionMode, IColumn } from '@fluentui/react/lib/index';
import { useDispatch, useSelector } from "react-redux";


import { RootState } from '../../../../store';
import { DetailListStickyHeader } from '../../common/DetailListStickyHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { getAclAccessColumns,  } from './helper';
import DetailsListWapper from '../../../common/DetailsListWapper';
import { IUserFunction } from '@solarforschools/sfs-core/dist/settings/types';
import { getUserFunctions, setUserFunctionFilter } from '../../../../store/admin/userFunction/action';
import { PageSize } from '../../common/common';
import Pagination from '../../common/Pagination';
import { IAclAccessState } from '../../../../store/admin/aclAccess/reducer';
import { deleteAclAccess, getAclAccesses, setAclAccessFilter } from '../../../../store/admin/aclAccess/action';
import OpenAclAccessModal from './AclAccessDialog';
import { IAclAccess } from './types';

const AclAccessList = ({ actions }: Props = { actions: [] }) => {
  const dispatch = useDispatch();
  const { aclAccesses, totalCount, aclAccessFilter }: IAclAccessState = useSelector<RootState, IAclAccessState>((state: RootState) => state.web.aclAccess)
console.log(aclAccesses,totalCount)
  const handleOnDelete = async (aclAccess: any) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Access",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete ${aclAccess.name}`,
      },
    });
    if (confirm) dispatch(deleteAclAccess(aclAccess));
  };

  const handleOnEdit = async (aclAccess: IAclAccess) => {
    await OpenAclAccessModal({ aclAccess })
  };

  const headerRow = getAclAccessColumns({
    actions: [
      {
        icon: 'Edit',
        onClick: handleOnEdit,
      },
      // {
      //   icon: "Trash",
      //   onClick: handleOnDelete
      // }
    ]
  })
  const [columns] = useState<IColumn[]>(headerRow)


  const handleOnSort = (field: string, order: string) => {
    const params = { ...aclAccessFilter, sortField: field, sortOrder: order };
    dispatch(setAclAccessFilter(params));
    dispatch(getAclAccesses(params))
  }

  const loadNextPage = (p: any) => {
    dispatch(setUserFunctionFilter({ ...aclAccessFilter, ...p }))
    dispatch(getUserFunctions())
  }
  const handleOnChangePageSize = (e: React.FormEvent<HTMLDivElement>, item: IDropdownOption<any> | undefined) => {
    const size: number = (item?.key && parseInt(item?.key.toString())) || 50
    const filters = { ...aclAccessFilter, page: 1, pageSize: size }
    dispatch(setAclAccessFilter(filters))
    dispatch(getAclAccesses(filters))
  };

  return (
    <>
      <div
        style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
        className="margin-top-md"
      >
        <div style={{ flex: "20%" }}>
          <span
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem", alignItems: "center" }}
          >
            {totalCount > 0 &&
              <>
                Displaying&nbsp;
                <Dropdown
                  selectedKey={aclAccessFilter.pageSize?.toString() || "50"}
                  options={PageSize}
                  styles={{ dropdown: { width: "80px" } }}
                  onChange={handleOnChangePageSize}
                />
                &nbsp;/ {totalCount}
              </>}
          </span>
        </div>

        <ActionButton
          allowDisabledFocus
          style={{ textAlign: 'right' }}
          onClick={async () => await OpenAclAccessModal({})}
        ><FontAwesomeIcon icon={faPlus} size='1x' color='#106ebe' />  &nbsp; Add Acl Access
        </ActionButton>
      </div>

      {totalCount > 0 ? (
        <>
          <div className="data-list-container">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsListWapper
                items={aclAccesses}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={DetailListStickyHeader}
                compact={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                canLoadMore={aclAccesses.length < totalCount}
                // onRequestLoadMore={loadNextPage}
                onSort={(field, order) => handleOnSort(field, order)}
              />
            </ScrollablePane>
          </div>
          <Pagination
            totalRecords={totalCount}
            onPaginate={(page) => loadNextPage({ page })}
            currentPage={aclAccessFilter?.page!}
            pageSize={aclAccessFilter.pageSize!}
          />
        </>
      ) : (
        <p style={{ paddingLeft: "1rem", textAlign: "center" }}>
          No Data Found!
        </p>
      )}
    </>
  )
};

export default AclAccessList;
interface OwnProps {
  actions: string[];
}

type Props = OwnProps;

